/* Veranstaltungsseite
Aufbau:
divClass: siehe layout.css


*/
import React from 'react'
import {useIntl} from "gatsby-plugin-intl"
import {  graphql } from 'gatsby'
import Layout from '../../components/layout'
import Header from '../../components/header'
import InfoLine from '../../components/infoline'
/* css */
import styles from '../../components/boxUmrandet.module.css'
/* pdf upload*/




const ProductPage = (props) => { 
const intl = useIntl()
  return(
  <Layout>
    
    <Header
       /* title= in dem blauen Querbalken */
      title={intl.formatMessage({ id: "va.Ueberschrift-1" })}

      text={intl.formatMessage({ id: "va.Ueberschrift-2" })}
    />
    {/* title= in dem blauen Querbalken */}
    <div className='row'>
      <div className='col-2'>

      </div>
      <div className='col-8'>
      <p> {intl.formatMessage({ id: "va.Text1-VaKeine" })}
      </p>
      </div>
    </div>
{/* ein Informationsblock für eine Veranstaltung */}
     
  {/* ende */}

  {/*
      <InfoLine
      distanceLeft='col-2'
      >
        <div className={styles.veranstaltungen}>  
          <h5>{intl.formatMessage({ id: "index.VaUe2-201119" })}</h5>
          <p className={styles.ort}> {intl.formatMessage({id: "index.VaUeDatum-201119"})}  </p>
          <p className={styles.ort}> {intl.formatMessage({id: "index.VaUeOrt-201119"})}  </p>
          
          <p> {intl.formatMessage({ id: "va.Text2-Va201129" })}</p>


        </div>
      </InfoLine>



      <InfoLine
      distanceLeft='col-2'
      >
        <div className={styles.veranstaltungen}>  
          <h5>{intl.formatMessage({ id: "index.VaUe3-201119" })}</h5>
          <p className={styles.ort}> {intl.formatMessage({id: "index.VaUeDatum-201119"})}  </p>
          <p className={styles.ort}> {intl.formatMessage({id: "index.VaUeOrt-201119"})}  </p>
          <p> {intl.formatMessage({ id: "va.Text3-Va201129" })}</p>
        </div>
      </InfoLine>
       
      */}
      

    </Layout>
)
}


export default ProductPage;

//fluidImage is defined on imagePage
export const pageQuery = graphql`
  query {

    imageOne: file(relativePath: { eq: "CAD-Zeichnungen-RK-1.jpg" }) {
      ...fluidImage
    }
    imageTwo: file(relativePath: { eq: "foto.png" }) {
      ...fluidImage
    }
    imageThree: file(relativePath: { eq: "CAD-Zeichnungen-RK-1.jpg" }) {
      ...fluidImage
    }
    imageFour: file(relativePath: { eq: "foto.png" }) {
      ...fluidImage
    }

  }
`